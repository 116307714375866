/*

TemplateMo 574 Mexant

https://templatemo.com/tm-574-mexant

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,500,600,700,800,900");
@import url("https://fonts.google.com/share?selection.family=Arvo:ital,wght@0,400;0,700;1,400;1,700");
@font-face {
  font-family: "Rockwell";
  src: url("../webfonts/Rockwell.eot");
  src: url("../webfonts/Rockwell.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/Rockwell.woff2") format("woff2"),
    url("../webfonts/Rockwell.woff") format("woff"),
    url("../webfonts/Rockwell.ttf") format("truetype"),
    url("../webfonts/Rockwell.svg#Rockwell") format("svg");
}
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Rockwell", serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #c69135;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 16px;
  line-height: 26px;
}

img {
  width: 100%;
  overflow: hidden;
}
.text-light,
.text-light p {
  color: #fff;
}
/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #000;
  font-family: "Rockwell", serif;
}

::selection {
  background: #ff511a;
  color: #fff;
}

::-moz-selection {
  background: #ff511a;
  color: #fff;
}

@media (max-width: 991px) {
  html,
  body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.brown-button a {
  font-size: 14px;
  color: #fff;
  background-color: #c69135;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.brown-button a:hover {
  opacity: 0.9;
}

.light-button a {
  font-size: 14px;
  color: #000;
  background-color: #fff;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.light-button a:hover {
  opacity: 0.9;
}

.dark-button {
  font-size: 14px;
  color: #c69135;
  background-color: #000;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.dark-button:hover {
  color: #a06909;
  background-color: #fff;
}

section {
  margin-top: 120px;
}

.section-heading {
  position: relative;
  z-index: 2;
  margin-top: 0px;
  text-align: center;
  margin-bottom: 70px;
  color: #fff;
}

.section-heading h6 {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}

.section-heading h4 {
  margin-top: 10px;
  line-height: 40px;
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
}

.section-heading p {
  margin-top: 30px;
  color: #fff;
}

/* 
---------------------------------------------
header
--------------------------------------------- 
*/
.background-header {
  background-color: #c69135;
  height: 75px !important;
  position: fixed !important;
  top: 0 !important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
}

.header-area {
  /* background-image: url(../images/header-bg.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  height: 110px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.header-area .main-nav {
  min-height: 77px;
  margin-top: 50px;
  background-color: #c69135;
}
.header-area.background-header .main-nav {
  margin-top: 0px;
}

.header-area .main-nav .logo {
  margin-top: -25px;
  width: 250px;
  line-height: 120px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.background-header .main-nav .logo {
  line-height: 80px;
  width: 132px;
  margin-top: -5px;
}

.background-header .nav {
  /* margin-top: 18px; */
}

.header-area .main-nav .nav {
  float: right;
  /* margin-top: 20px; */
  margin-right: 20px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 27px;
  /* border: transparent; */
  letter-spacing: 1px;
  padding: 25px;
}

.header-area .main-nav .nav li:last-child a:hover {
  color: #fff;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li:last-child a,
.background-header .main-nav .nav li.has-sub ul.sub-menu li:last-child a {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 0px;
  padding-top: 0px !important;
  height: 40px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li:last-child a:hover,
.background-header .main-nav .nav li.has-sub ul.sub-menu li:last-child a:hover {
  padding-left: 25px !important;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #fff;
  background-color: #000;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #fff;
  background-color: #000;
}

.header-area .main-nav .nav li.has-sub {
  position: relative;
  padding-right: 15px;
}

.header-area .main-nav .nav li.has-sub:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 2px;
  top: 12px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu {
  position: absolute;
  width: 140px;
  background-color: #fff;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 40px;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li a {
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a !important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.has-sub ul li a:hover {
  background: #000;
  color: #fff !important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.has-sub ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.has-sub:hover ul.sub-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 23px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #fff;
}

.header-area.header-sticky {
  min-height: 77px;
}

/* .header-area .nav {
  margin-top: 30px;
} */

.header-area.header-sticky .nav li a.active {
  color: #fff;
  background-color: #000;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .logo {
    color: #1e1e1e;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #ff511a !important;
    opacity: 1;
  }
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }
  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 0px;
    line-height: 80px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area.header-sticky .nav {
    margin-top: 100px !important;
  }
  .background-header.header-sticky .nav {
    margin-top: 80px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #ff511a !important;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
    transition: all 0s;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    height: auto;
  }
  .header-area .main-nav .nav li.has-sub:after {
    color: #3b566e;
    right: 30px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul,
  .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 767px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}

/* 
---------------------------------------------
banner
--------------------------------------------- 
*/

.swiper-container {
  height: calc(100vh - 0px);
  margin: 0px;
  background-color: #212741;
}

.swiper-slide {
  overflow: hidden;
}

.slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
}

.swiper-slide-active h2 {
  animation: fadeInLeft 0.8s;
  -webkit-animation-delay: 1s;
  animation-delay: 0.3s;
  opacity: 1;
}

.swiper-slide-active p {
  animation: fadeInRight 0.8s;
  -webkit-animation-delay: 1s;
  animation-delay: 0.3s;
  opacity: 1;
}

.swiper-slide-active .div-dec {
  animation: fadeIn 0.8s;
  -webkit-animation-delay: 1s;
  animation-delay: 0.3s;
  opacity: 1;
}

.swiper-slide-active .buttons .brown-button,
.swiper-slide-active .buttons .light-button {
  animation: fadeInUp 0.8s;
  -webkit-animation-delay: 1s;
  animation-delay: 0.3s;
  opacity: 1;
}

.slide-inner .header-text {
  position: absolute;
  width: 100%;
  top: 80%;
  display: flex;
  justify-content: center;
  left: 0;
}

.slide-inner .header-text h2 {
  position: relative;
  z-index: 2;
  font-size: 56px;
  color: #fff;
  font-weight: 700;
  opacity: 1;
  visibility: visible;
  line-height: 70px;
  margin-bottom: 40px;
}

.slide-inner .header-text .div-dec {
  width: 80px;
  height: 6px;
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 40px;
  opacity: 1;
  visibility: visible;
}

.slide-inner .header-text p {
  color: #fff;
  margin-right: 60px;
  margin-bottom: 50px;
  opacity: 1;
  visibility: visible;
}

.slide-inner .header-text h2 em {
  font-style: normal;
  color: #ff511a;
}

.slide-inner .header-text .buttons {
  display: flex;
  opacity: 1;
  visibility: visible;
}

.slide-inner .header-text .buttons .brown-button {
  display: inline;
  float: left;
  margin-right: 20px;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}

/*
---------------------------------------------
services
---------------------------------------------
*/

section.services {
  margin-top: 90px;
}

.services .service-item {
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.services .service-item:hover i {
  margin-top: 15px;
}

.services .service-item h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.services .service-item i {
  transition: all 0.5s;
  float: left;
  font-size: 64px;
  color: #c69135;
  margin-bottom: 30px;
  margin-right: 30px;
}

/*
---------------------------------------------
cta
---------------------------------------------
*/

section.simple-cta {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding: 80px 0px;
  background-color: #c69135;
}

section.simple-cta .left-image {
  margin-right: 45px;
}

section.simple-cta h4 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  line-height: 50px;
}

section.simple-cta h4 em {
  font-style: normal;
  color: #c69135;
}

section.simple-cta h4 strong {
  color: #ff511a;
  font-weight: 700;
}

section.simple-cta p {
  color: #fff;
  margin-top: 30px;
  margin-bottom: 40px;
}

section.simple-cta .buttons {
  display: flex;
  justify-content: center;
}

section.simple-cta .buttons .light-button {
  margin-left: 20px;
}

section.simple-cta .buttons .light-button,
section.simple-cta .buttons .brown-button {
  margin-top: 25px;
}

/*
---------------------------------------------
facility
---------------------------------------------
*/

section.facility {
  margin-top: 90px;
}

section.facility .section-heading {
  padding-top: 30px;
}

section.facility .naccs {
  position: relative;
}

section.facility .naccs .menu div {
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  border-radius: 0 !important;
  margin-right: 0;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  cursor: pointer;
  vertical-align: middle;
  font-weight: 500;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 1px solid #fff;
}

section.facility .naccs .menu div.active {
  color: #fff;
  background-color: #c69135;
  border: 1px solid #c69135;
}

section.facility ul.nacc {
  margin-top: 5px !important;
  position: relative;
  min-height: 100%;
  list-style: none;
  margin: 0;
}

section.facility ul.nacc li {
  background-color: #fff;
  overflow: hidden;
  opacity: 0;
  transform: translateX(50px);
  position: absolute;
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

section.facility ul.nacc li .main-list,
section.facility ul.nacc li .list-item {
  border-bottom: 1px solid #c69135;
  margin-bottom: 25px;
  padding-bottom: 15px;
  display: inline-block;
}

section.facility ul.nacc li .last-item {
  margin-bottom: 0px;
}

section.facility ul.nacc li span.title {
  font-size: 15px;
  color: #212741;
  font-weight: 500;
  display: inline-block;
  width: 24%;
}

section.facility ul.nacc li span.item-title {
  color: #c69135 !important;
  font-weight: 500 !important;
}

section.facility ul.nacc li span.item {
  font-size: 28px;
  font-weight: 400;
  display: block;
}

section.facility ul.nacc li.active {
  position: relative;
  transition-delay: 0.3s;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
  padding: 25px;
}

section.facility .right-content {
  margin-left: 60px;
}

section.facility .right-content h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 40px;
}

section.facility .right-content p {
  margin-bottom: 50px;
}
.bullets {
  width: 24px;
  margin-right: 10px;
}
.mb10 {
  margin-bottom: 10px;
}

/*
---------------------------------------------
calculator
---------------------------------------------
*/

section.calculator {
  margin-top: 150px;
  /* background-image: url(../images/calculator-bg.jpg); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding: 80px 0px;
}

section.calculator .left-image {
  margin: -110px 45px -80px 0px;
}

section.calculator .section-heading {
  text-align: left;
}

section.calculator .section-heading h4 {
  color: #fff;
}

#calculate label {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
}

#calculate input,
#calculate select {
  border: 4px solid #a06909;
  border-radius: 0;
  width: 100%;
  height: 42px;
  font-size: 14px;
  margin-bottom: 30px;
  outline: none;
}

#calculate select {
  cursor: pointer;
}

#calculate button.light-button {
  font-size: 14px;
  color: #000;
  background-color: #fff;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: none;
  transition: all 0.3s;
}

#calculate button.light-button:hover {
  opacity: 0.9;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-repeat: no-repeat;
  /* background: url(../images/Icon/fa-regular-envelope.svg) no-repeat; */
  width: 25px;
  height: 25px;
  border-width: thin;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 3%;
}
input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 10%;
}
input::-webkit-datetime-edit {
  position: relative;
  left: 10%;
}
.icon-message input[type="text"] {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  /* background-repeat:  no-repeat;  */
  /* background: url(../images/Icon-fa-regular-envelope) no-repeat; */
  /* background: 'mdi mdi-24px mdi-clock-outline' no-repeat; */
  width: 25px;
  height: 25px;
  border-width: thin;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-repeat: no-repeat;
  /* background: url(../images/Icon/fa-regular-envelope.svg) no-repeat; */
  width: 25px;
  height: 25px;
  border-width: thin;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 3%;
}
fieldset {
  position: relative;
}
/*
---------------------------------------------
gallery
---------------------------------------------
*/
.photos-grid-container {
  height: 100%;
  display: grid;
  /* grid-template-columns: 1fr 1fr;*/
  /* grid-template-rows: 1fr;  */
  grid-gap: 0;
  align-items: start;
  /* Define Auto Row size */
  /* grid-auto-rows: 270px;  */
  /*Define our columns */
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  */

  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 220px;
  /* grid-gap: 1rem; */
  /* grid-auto-flow: dense; */
  overflow: hidden;
}

.photos-grid-container .img-box:nth-child(1) {
  grid-column: auto / span 2;
}
.photos-grid-container .img-box:nth-child(6) {
  grid-column: auto / span 2;
}
@media (max-width: 580px) {
  .photos-grid-container {
    grid-template-columns: 1fr !important;
  }
  .photos-grid-container .img-box:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  .photos-grid-container .img-box:nth-child(6) {
    grid-column: 1;
    grid-row: 1;
  }
  .testimonials .item .right-image {
    position: initial !important;
  }

  .testimonials .item img {
    top: -49px !important;
  }
}
.photos-grid-container .img-box {
  border: 3px solid #000;
  position: relative;
}
.photos-grid-container .img-box:hover .transparent-box {
  background-color: rgba(0, 0, 0, 0.6);
}
.photos-grid-container .img-box:hover .caption {
  transform: translateY(-5px);
}
.photos-grid-container img {
  max-width: 100%;
  display: block;
  height: auto;
}
.photos-grid-container .caption {
  color: white;
  transition: transform 0.3s ease, opacity 0.3s ease;
  font-size: 1.5rem;
}
.photos-grid-container .transparent-box {
  height: 230px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .photos-grid-container .main-photo {
    grid-row: 1;
    grid-column: 1;
  }
  .photos-grid-container .sub {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0em;
  }
  .photos-grid-container .sub:nth-child(0) {
    grid-column: 1;
    grid-row: 1;
  }
  .photos-grid-container .sub:nth-child(1) {
    grid-column: 2;
    grid-row: 1;
  }
  .photos-grid-container .sub:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
  .photos-grid-container .sub:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  } */

.hide-element {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* 
---------------------------------------------
testimonials
--------------------------------------------- 
*/

section.testimonials {
  margin-top: 90px;
}

section.testimonials .section-heading {
  padding-top: 30px;
}

.testimonials .item {
  border-radius: 15px;
  padding: 50px;
  background-color: #fff;
  position: relative;
  margin: 15px;
  margin-top: 55px;
  margin-bottom: 55px;
  width: 100%;
  height: auto;
}

.testimonials .item i {
  width: 70px;
  height: 70px;
  background-color: #c69135;
  display: inline-block;
  text-align: center;
  line-height: 70px;
  color: #fff;
  font-size: 44px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.testimonials .item p {
  font-size: 16px;
  font-style: italic;
  text-align: center;
}

.testimonials .item h4 {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 600;
  position: relative;
  text-align: center;
  color: #a06909;
}

.testimonials .item span {
  margin-top: 5px;
  display: block;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #a1a0a0;
}

.testimonials .item img { 
  position: absolute;
  /* right: 0; */
  top: 26px;
  transform: translateY(-50%);
  max-width: 100px;
  height: 100px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  filter: drop-shadow(4px 3px 0px #000000);
  left: calc(50% - 40px);
  z-index: 12;
}
.testimonials .item .right-image {
  position: relative;
  display: block;
  top: -228px;
}

.testimonials .owl-dots {
  text-align: center;
  margin-top: 30px;
}

.testimonials .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  background-color: #ffa88c;
  border-radius: 50%;
  margin: 0px 4px;
}

.testimonials .owl-dots .active {
  background-color: #ff511a;
}

.testimonials .owl-prev i,
.testimonials .owl-next i {
  color: #fff;
  font-size: 50px;
}
.owl-carousel .owl-nav [class*="owl-"] {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.owl-carousel .owl-nav [class*="owl-"].disabled:hover {
  background-color: #d6d6d6;
}
.owl-carousel {
  position: relative;
}
.owl-carousel .owl-next,
.owl-carousel .owl-prev {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  font-size: 20px;
  color: #fff;
  border: 1px solid #ddd;
  text-align: center;
}
.owl-carousel .owl-next img,
.owl-carousel .owl-prev img {
  width: 30px;
}
.owl-carousel .owl-next img:hover svg,
.owl-carousel .owl-prev img:hover svg {
  fill: #fff;
}
.owl-carousel .owl-prev {
  left: -70px;
}
.owl-carousel .owl-next {
  right: -70px;
}

/* 
---------------------------------------------
partners
--------------------------------------------- 
*/

section.partners {
  background-color: #212741;
  padding: 80px 0px;
}

section.partners .item {
  margin: 0px 15px;
  cursor: pointer;
  transition: all 0.3s;
}

section.partners .item:hover {
  opacity: 0.75;
}

/* 
---------------------------------------------
footer 
--------------------------------------------- 
*/

footer {
  background-color: #000;
  text-align: center;
}

footer p {
  color: #fff;
  font-weight: 300;
}

footer p a {
  color: #c69135;
}
footer .Copyright a:hover {
  color: #fff;
}
footer .topFooter {
  padding: 215px 0 98px 0;
  background-color: #a06909;
  position: relative;
}
footer .topFooter::after {
  content: "";
  position: absolute;
  top: 0;
  transform: translate(-50%);
  width: 0;
  height: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-top: 150px solid #000;
}
footer .topFooter::before {
  content: "";
  position: absolute;
  top: 20px;
  width: 70px;
  height: 90px;
  transform: translate(-50%);
  z-index: 1;
  background-image: url(../images/logo.svg);
  background-size: cover;
}
footer .topFooter img {
  width: 50px;
  margin-bottom: 40px;
}
footer .Copyright {
  padding: 30px 0;
}
footer .Copyright a {
  color: #c69135;
}
footer .Copyright span a {
  padding: 0 15px;
}
footer .Copyright p {
  padding: 10px 15px;
  font-size: 12px;
  color: #8d9093;
}
.font-weight-bold {
  font-weight: 800;
}
.border-right {
  border-right: 1px solid #000;
}

/* 
---------------------------------------------
page heading
--------------------------------------------- 
*/

.page-heading {
  background-image: url(../images/X-17-Modern-002-SLIDE.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 215px 0px 120px 0px;
  text-align: center;
  position: relative;
}
.page-heading:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transition: all 1s;
  -webkit-transition: all 1s;
  z-index: 1;
}
.page-heading h2 {
  font-size: 56px;
  color: #fff;
  font-weight: 700;
  z-index: 2;
  position: relative;
}

.page-heading .div-dec {
  width: 80px;
  height: 5px;
  border-radius: 0;
  background-color: #fff;
  margin: 20px auto 0 auto;
  z-index: 2;
  position: relative;
}

/* 
---------------------------------------------
about us page
--------------------------------------------- 
*/

section.top-section .accordions {
  border-radius: 0;
  padding: 0px 30px;
  background-color: #fff;
  margin-left: 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
.accordions .accordion {
  border-bottom: 1px solid #eee;
}
.accordions .last-accordion {
  border-bottom: none;
}
.accordion-head {
  padding: 25px 0px !important;
  font-size: 22px;
  font-weight: 600;
  color: #212741;
  cursor: pointer;
  transition: color 200ms ease-in-out;
}
@media screen and (min-width: 768px) {
  .accordion-head {
    padding: 1rem;
    font-size: 1.2rem;
  }
}
.accordion-head .icon {
  float: right;
  transition: transform 200ms ease-in-out;
}
.accordion-head.is-open {
  color: #c69135;
  border-bottom: none;
}
.accordion-head.is-open .icon {
  transform: rotate(45deg);
}
.accordion-body {
  padding: 0px;
  overflow: hidden;
  height: 0;
  transition: height 300ms ease-in-out;
  border-bottom: 1px solid #fff;
}
.accordion-body > .content p {
  padding: 0px 0px 30px 0px;
  padding-top: 0;
}

section.what-we-do {
  margin-bottom: -30px;
}

.what-we-do .left-content {
  margin-right: 60px;
}

.what-we-do .left-content h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 40px;
}

.what-we-do .left-content p {
  margin-bottom: 40px;
}

.what-we-do .right-items .item {
  border-radius: 5px;
  padding: 40px 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: relative;
}

.what-we-do .right-items .item em {
  position: absolute;
  font-style: normal;
  font-size: 44px;
  color: #c69135;
  font-weight: 600;
  right: 15px;
  top: 5px;
  transition: all 0.3s;
}

.what-we-do .right-items .item h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 22px;
}

.what-we-do .right-items .item:hover em {
  top: 10px;
}

/* 
---------------------------------------------
services page
--------------------------------------------- 
*/

.main-services .service-item {
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #ecf8f2;
}

.main-services .last-service {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.main-services .left-image {
  margin-right: 15px;
}

.main-services .left-image img {
  border-radius: 0;
}

.main-services .right-image {
  margin-left: 15px;
}

.main-services .right-image img {
  border-radius: 0;
}

.main-services .right-text-content {
  margin-left: 15px;
}

.main-services .left-text-content {
  margin-right: 15px;
}

.main-services .right-text-content i,
.main-services .left-text-content i {
  font-size: 44px;
  color: #fff;
  background-color: #c69135;
  width: 100px;
  height: 100px;
  text-align: center;
  display: inline-block;
  line-height: 100px;
  border-radius: 0;
}

.main-services .right-text-content h4,
.main-services .left-text-content h4 {
  font-size: 36px;
  font-weight: 700;
  color: #c69135;
  margin-top: 40px;
  margin-bottom: 30px;
}
section.service-details {
  margin-bottom: 80px;
}
section.service-details .naccs {
  position: relative;
}

section.service-details .naccs .menu {
  text-align: center;
}

section.service-details .naccs .menu div {
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  border-radius: 0 !important;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  cursor: pointer;
  vertical-align: middle;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
}

section.service-details .naccs .menu div.active {
  background-color: #c69135;
  color: #fff;
  border: 1px solid #c69135;
}

section.service-details ul.nacc {
  margin-top: 10px !important;
  position: relative;
  min-height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

section.service-details ul.nacc li {
  background-color: #fff;
  overflow: hidden;
  opacity: 0;
  transform: translateX(50px);
  position: absolute;
  list-style: none;
  border-radius: 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

section.service-details ul.nacc li .left-image {
  position: absolute;
  margin-right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 45%;
}

section.service-details ul.nacc li .right-content {
  padding: 20px 20px 20px 0;
  margin-left: 50%;
}

section.service-details ul.nacc li .right-content h4 {
  font-size: 24px;
  color: #212741;
  margin-bottom: 25px;
  font-weight: 700;
  line-height: 40px;
  margin-right: 120px;
}

section.service-details ul.nacc li .right-content ul {
  margin-top: 20px;
  margin-bottom: 20px;
}

section.service-details ul.nacc li .right-content span {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #212741;
  font-style: italic;
  margin-bottom: 12px;
}

section.service-details ul.nacc li .right-content .last-span {
  margin-bottom: 0px;
}

section.service-details ul.nacc li .right-content p {
  margin-bottom: 30px;
}

section.service-details ul.nacc li.active {
  position: relative;
  transition-delay: 0.3s;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
}
/*---------------------
  About
-----------------------*/
section.about-us {
  margin-top: 0;
}
.about-us .about-text-warp {
  background: #c69135;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 100px 15px;
}

.about-us .about-text {
  max-width: 560px;
}

.about-us .about-text h2 {
  color: #000;
  font-size: 36px;
  line-height: 48px;
  font-weight: 500;
  margin-bottom: 25px;
}

.about-us .about-text p {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 32px;
}

.about-us .about-img {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
}

.about-us .about-img img {
  display: inline-block;
  width: 100%;
  height: 337px;
  float: left;
}

.about-us .about-img .l-img {
  height: 399px;
}

/*---------------------
  Team
-----------------------*/

.team-section .card {
  margin: 10px;
  background: #111;
  transition: 0.3s;
  border-radius: 0;
}

.team-section .card:hover {
  background: #c69135;
  color: #000;
}

.team-section .card .content {
  transition: 0.3s;
}

.team-section .card .content:hover {
  transform: scale(1.069);
}

.team-section .card .profile {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  margin: 20px auto;
  border: 5px solid #c69135;
  color: #000;
}

.team-section .card .profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-section .card .detail h4,
.team-section .card .detail h6 {
  margin: 10px 0;
}
.team-section .card:hover .detail h4,
.team-section .card:hover .detail h6 {
  margin: 10px 0;
  color: #000;
}
.team-section .card .social {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.team-section .card .detail .social a {
  margin: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 44px;
  text-align: center;
  transition: 0.2s;
}

.team-section .card .social a:hover {
  background: #222;
}

.team-section .card .social a i {
  font-size: 21px;
  color: white;
}
/*---------------------
  Counter
-----------------------*/

.b-top {
  padding-top: 94px;
  padding-bottom: 70px;
  border-top: 1px solid #666;
}
.br0 {
  border: 0;
}
.single-counter .counter-img {
  float: left;
}

.single-counter .counter-img img {
  display: inline-block;
  margin-right: 25px;
}

.single-counter .counter-info {
  display: inline-block;
}

.single-counter .counter-info span {
  color: #fff;
  display: inline-block;
  font-size: 60px;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 13px;
}

.single-counter .counter-info p {
  color: #c69135;
  font-size: 18px;
  font-weight: 400;
}

/* 
---------------------------------------------
contact us page
--------------------------------------------- 
*/

section.map .info-item {
  text-align: center;
  padding: 40px 30px;
  border-radius: 5px;
  background-color: #c69135;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-top: -60px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  z-index: 2;
  transition: all 0.5s;
}

section.map .info-item:hover {
  margin-top: -70px;
}

section.map .info-item i {
  font-size: 36px;
  color: #000;
  margin-bottom: 25px;
}

section.map .info-item h4 {
  font-size: 18px;
  color: #000;
  margin-bottom: 12px;
}

section.map .info-item a {
  font-size: 15px;
  color: #fff;
  transition: all 0.3s;
}

section.map .info-item a:hover {
  color: #000;
}

#contact input {
  width: 100%;
  height: 50px;
  background-color: #f7f7f7;
  color: #000;
  border-radius: 0;
  border: none;
  padding: 0px 15px;
  font-size: 14px;
  outline: none;
  margin-bottom: 30px;
}

#contact textarea {
  width: 100%;
  height: 180px;
  max-height: 24px;
  min-height: 150px;
  background-color: #f7f7f7;
  color: #000;
  border-radius: 0;
  border: none;
  padding: 15px 15px;
  font-size: 14px;
  outline: none;
  margin-bottom: 30px;
}

#contact input::placeholder,
#contact textarea::placeholder {
  color: #555;
}
#contact button.orange-button {
  font-size: 14px;
  color: #fff;
  background-color: #a06909;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 0;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  border: none;
  transition: all 0.3s;
  margin-bottom: 25px;
}

#contact button.orange-button:hover {
  background-color: #c69135;
}

.pb80 {
  padding-bottom: 80px;
}
/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/

body {
  overflow-x: hidden;
}

@media (max-width: 992px) {
  .header-area .main-nav .nav li:last-child {
    display: none;
  }
  .header-area .main-nav .nav li ul li:last-child {
    display: inline-block;
  }
  .header-area {
    background-color: #212741;
  }
  .slide-inner .header-text {
    width: 80%;
  }
  .simple-cta {
    text-align: center;
  }
  section.simple-cta .buttons {
    justify-content: center;
  }
  section.about-us .naccs .menu div,
  section.service-details .naccs .menu div {
    margin-right: 15px;
    font-size: 15px;
    padding: 15px 20px;
  }
  section.about-us .right-content {
    margin-left: 0px;
    margin-top: 60px;
  }
  section.calculator .left-image {
    display: none;
  }
  .testimonials .item img {
    max-width: 200px;
    right: 50px;
  }

  section.partners .item {
    margin: 15px;
  }
  section.top-section .accordions {
    margin-left: 0px;
    margin-top: 45px;
  }
  .what-we-do .left-content {
    margin-right: 0px;
    margin-bottom: 45px;
  }
  .main-services .left-image {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .main-services .right-text-content {
    margin-left: 0px;
  }
  .main-services .right-image {
    margin-left: 0px;
    margin-top: 30px;
  }
  section.service-details ul.nacc li .left-image {
    position: relative;
    margin-right: 0px;
    transform: translateY(0px);
  }
  section.service-details ul.nacc li .left-image img {
    border-radius: 15px;
  }
  section.service-details ul.nacc li .right-content {
    padding: 50px;
    margin-left: 0px;
  }
  section.service-details ul.nacc li .right-content h4 {
    margin-right: 0px;
  }
  section.service-details ul.nacc li.active {
    display: inline-block;
  }
  section.service-details ul.nacc li {
    display: none;
  }
  section.map .info-item {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .logo {
    line-height: 100px;
  }
  .background-header .main-nav .logo {
    line-height: 80px;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    display: none;
  }
  .slide-inner .header-text p {
    display: none;
  }
  .slide-inner .header-text {
    text-align: center;
    width: 100%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .slide-inner .header-text .div-dec {
    margin-left: auto;
    margin-right: auto;
  }
  .slide-inner .header-text .buttons {
    display: inline-flex;
  }
  .slide-inner .header-text .buttons .brown-button {
    float: none;
  }
  .services .service-item i {
    margin-bottom: 35px;
  }
  section.about-us ul.nacc li span.item-title {
    font-size: 14px !important;
  }
  section.about-us ul.nacc li span.item {
    font-size: 13px;
  }
  .testimonials .item i {
    margin-bottom: 60px;
  }
  .testimonials .item img {
    max-width: 100px;
    right: 50px;
    top: 35px;
    transform: translateY(0);
  }
  .testimonials .item {
    padding: 50px;
  }
}